import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, InputAdornment, TextField, Hidden } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { MdEmail } from "react-icons/md";
import { MdGroup } from "react-icons/md";

import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import AuthTitleBar from '@layouts/AuthTitleBar';

export default function ForgetPassword() {
    const theme = useTheme();
    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState({
        email: '',
        username: ''
    });
    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const resetPassword = () => {
        setLoading(true);
        setInputErrors();
        postUrl('/forgot-password', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <div className='flex-c-m' style={{ minHeight: '100vh' }}>
                <AuthTitleBar />
                
                <div className='gradient-gold-border p-tb-70'>
                    <div className='box-auth txt-center'>
                        <div className='p-b-20'>
                        <p className='fs-30 p-b-10 text-gold' ><b>{t('forgetPassword.title')}</b></p>
                        <p className='txt-caption'>{t('forgetPassword.desc')}</p>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                placeholder={t('user.email')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEmail className="img-style" />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                type="email"
                                autoComplete="email"
                                color='primary'
                                value={state.email}
                                onChange={({ target }) => setState({ ...state, email: target.value })}
                                // helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                // error={inputErrors && inputErrors.email ? true : false}
                                className={classes.textFieldStyle}
                            />
                            <TextField
                                variant="standard"
                                placeholder={t('user.username')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdGroup className="img-style" />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                type="username"
                                autoComplete="username"
                                color='primary'
                                value={state.username}
                                onChange={({ target }) => setState({ ...state, username: target.value.replace(/[^\w\s]/gi, "").toLowerCase().replace(/ /g, '')})}
                                // helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                // error={inputErrors && inputErrors.username ? true : false}
                                className={classes.textFieldStyle}
                            />
                            {
                                _.size(inputErrors) > 0
                                &&
                                ((Object.keys(Object.assign({}, inputErrors))).includes('username') || (Object.keys(Object.assign({}, inputErrors))).includes('email'))
                                &&
                                (
                                    <p className='fs-13 txt-left text-gold-short'>
                                        {t('error.recordNotFound')}
                                    </p>
                                )
                            }
                            <Button fullWidth variant="contained" color="primary" onClick={resetPassword} className='big-button' style={{ marginTop: 20 }} >
                                {t('button.reset')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: '75%',
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
    leftBg: {
        flex: 0.3,
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url('../images/auth/login-red-bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    rightBg: {
        flex: 0.7
    },
    imgStyle: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '50%',
        height: '85%',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: -10,
        right: 0,
        objectFit: 'contain'
    },
    fieldBox:{
        position:'absolute',
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        right: 0, 
        left: '30%',
        [theme.breakpoints.down('md')]:{
            left:0,
        },
        [theme.breakpoints.down('sm')]:{
            left:0,
        },
        [theme.breakpoints.down('xs')]:{
            left:0,
        },
    },
    // descStyle:{
    //     width:'70%',
    //     [theme.breakpoints.down('md')]:{
    //         width:'100%'
    //     },
    //     [theme.breakpoints.down('sm')]:{
    //         width:'100%'
    //     },
    //     [theme.breakpoints.down('xs')]:{
    //         width:'100%'
    //     },
    // }
}));