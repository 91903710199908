import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';

import { BottomNavigation, BottomNavigationAction, Box, Paper, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography, createTheme } from '@mui/material';

import { storePaths } from '@utils/Tools';
import { FaArrowTrendUp, FaArrowsRotate } from "react-icons/fa6";

import { Home, AddShoppingCart, DonutLarge, Wallet, Settings } from '@mui/icons-material';

const MAIN_NAVIGATE = ['home', 'funds', 'account', 'plans', 'trade'];
const SHOW_BAR = ['/', '/setting', '/funds/wallet', '/funds/bonus', '/account', '/plans', '/trade', '/trade/marketplace', '/trade/trading', '/trade/transaction-details']
const SELLING_BTN = ['/trade', '/trade/marketplace', '/trade/trading', '/trade/transaction-details']

const BottomNavigationBar = memo(() => {
    const { t } = useTranslation();
    const { accessToken } = useSelector(state => state.general);
    const [value, setValue] = useState('home');
    const [selectedIcon, setSelectedIcon] = useState('home');
    let location = useLocation();
    let path = location.pathname.split('/');
    let navigate = useNavigate();
    const theme = useTheme();

    const tradeActions = [
        { icon: <FaArrowTrendUp size="16px" style={{ color: 'red' }} />, name: t('trade.sell'), urlLink: '/trade/selling-form' },
        { icon: <FaArrowsRotate size="16px" style={{ color: 'blue' }} />, name: t('trade.convert'), urlLink: '/trade/convert' },
    ];
    const [tradeOpen, setTradeOpen] = useState(false);
    const handleTradeOpen = () => setTradeOpen(true);
    const handleLinkClick = (urlLink) => {
        setTradeOpen(false);
        navigate(urlLink, { state: { from: storePaths(location) } });
    };

    useEffect(() => {
        let temp_value = _.intersection(MAIN_NAVIGATE, path);
        if (_.size(temp_value) > 0) {
            setValue(temp_value[0]);
            setSelectedIcon(temp_value[0]); 
        } else {
            setValue('home');
            setSelectedIcon('home');
        }
    }, [path]);
    

    return (
        <>
            {
                _.includes(SHOW_BAR, location.pathname)
                    ?
                    <Paper sx={{ position: 'fixed', zIndex: 100, bottom: 0, maxWidth: 'inherit', width: '100%', textAlign: "center", justifyContent: "flex-start" }} elevation={3}>
                        <BottomNavigation
                            showLabels
                            style={{
                                background: theme.palette.secondary.main,
                                color: 'white',
                            }}
                            
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                setSelectedIcon(newValue);
                            }}
                        >
                            <BottomNavigationAction
                                label={t('title.home')}
                                value="home"
                                icon={
                                    <Home />
                                    // <div>
                                    //     <img
                                    //         src={`/images/footer_icon/home.png`}
                                    //         // src={`/images/footer_icon/${selectedIcon === 'home' ? 'home-sel.png' : 'home.png'}`}
                                    //         alt={t('title.home')}
                                    //         className="img-style"
                                    //         loading='lazy'
                                    //     />
                                    // </div>
                                }
                                to="/"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />
                            <BottomNavigationAction
                                label={t('title.plan')}
                                value="plans"
                                icon={
                                    <AddShoppingCart /> 
                                    // <div>
                                    //     <img
                                    //         src={`/images/footer_icon/package.png`}
                                    //         // src={`/images/footer_icon/${selectedIcon === 'plans' ? 'package-sel.png' : 'package.png'}`}
                                    //         alt={t('title.plan')}
                                    //         className="img-style"
                                    //         loading='lazy'
                                    //     />
                                    // </div>
                                }
                                to="/plans"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />
                            {/* {
                                _.includes(SELLING_BTN, location.pathname)
                                    ?
                                    // <BottomNavigationAction
                                    //     label={t('title.trade')}
                                    //     value="trade"
                                    //     icon={null}
                                    //     sx={{
                                    //         display: "flex",
                                    //         justifyContent: "flex-end",
                                    //         marginBottom: "5px"
                                    //     }}
                                    // />
                                    <BottomNavigationAction
                                        label={''}
                                        value="trade"
                                        icon={
                                            // _.includes(SELLING_BTN, location.pathname)
                                            //     ?
                                            //     <div className='flex-c-b'>
                                            //         <Box sx={{ flexGrow: 1 }}>
                                                        
                                            //             <SpeedDial
                                            //                 ariaLabel="SpeedDial tooltip example"
                                            //                 sx={{
                                            //                     position: 'relative', bottom: 10,
                                            //                     '& .MuiFab-primary': {
                                            //                         // color: theme.palette.primary.main, 
                                            //                         color: '#eee', 
                                            //                         // background: '#eee', 
                                            //                         background: 'linear-gradient(127.58deg, #82512D 15.58%, #CE8F3C 88.13%)', 
                                            //                         width: 65, 
                                            //                         height: 65, 
                                            //                         borderRadius: 48, 
                                            //                         boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 3px 3px 3px 0px rgba(255, 255, 255, 0.76) inset, -2px -2px 3px 0px rgba(0, 0, 0, 0.14) inset',
                                            //                         '&:hover': { backgroundColor: theme.palette.secondary.main }
                                            //                     },
                                            //                 }}
                                            //                 icon={<SpeedDialIcon />}
                                            //                 onClose={() => setTradeOpen(false)}
                                            //                 onOpen={handleTradeOpen}
                                            //                 open={tradeOpen}
                                            //             >
                                            //                 {tradeActions.map((action) => (
                                            //                     <SpeedDialAction
                                            //                         key={action.name}
                                            //                         icon={
                                            //                             <Box>
                                            //                                 {action.icon}
                                            //                                 <br />
                                            //                                 <Typography style={{ fontSize: 9 }}>{action.name}</Typography>
                                            //                             </Box>
                                            //                         }
                                            //                         // tooltipTitle={action.name}
                                            //                         // tooltipOpen
                                            //                         onClick={() => handleLinkClick(action.urlLink)}
                                            //                         sx={{
                                            //                             width: 55,
                                            //                             height: 55,
                                            //                             lineHeight: "normal",
                                            //                         }}
                                            //                     />
                                            //                 ))}
                                            //             </SpeedDial>
                                            //         </Box>
                                            //     </div>
                                            //     :
                                                null
                                        }
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginBottom: "5px"
                                        }}
                                    />
                                    :
                                    <BottomNavigationAction
                                        label={t('title.trade')}
                                        value="trade"
                                        icon={
                                            <DonutLarge /> 

                                        //     <div>
                                        //         <img
                                        //             src={`/images/footer_icon/trade.png`}
                                        //             // src={`/images/footer_icon/${selectedIcon === 'trade' ? 'trade-sel.png' : 'trade.png'}`}
                                        //             alt={t('title.trade')}
                                        //             className="img-style"
                                        //             loading='lazy'
                                        // />
                                        //     </div>
                                        }
                                        to="/trade"
                                        state={{ from: storePaths(location) }}
                                        component={RouterLink}
                                    />
                            } */}

                            {/* <BottomNavigationAction
                                label={t('title.tradeName')}
                                value="trade"
                                icon={
                                    <DonutLarge /> 

                                //     <div>
                                //         <img
                                //             src={`/images/footer_icon/trade.png`}
                                //             // src={`/images/footer_icon/${selectedIcon === 'trade' ? 'trade-sel.png' : 'trade.png'}`}
                                //             alt={t('title.trade')}
                                //             className="img-style"
                                //             loading='lazy'
                                // />
                                //     </div>
                                }
                                to="/trade"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            /> */}

                            <BottomNavigationAction
                                label={t('title.wallet')}
                                value="funds"
                                icon={
                                    <Wallet /> 

                                    // <div>
                                    //     <img
                                    //         src={`/images/footer_icon/wallet.png`}
                                    //         // src={`/images/footer_icon/${selectedIcon === 'funds' ? 'wallet-sel.png' : 'wallet.png'}`}
                                    //         alt={t('title.wallet')}
                                    //         className="img-style"
                                    //         loading='lazy'
                                    //     />
                                    // </div>
                                }
                                to="/funds/wallet"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />
                            <BottomNavigationAction
                                label={t('title.setting')}
                                value="account"
                                icon={
                                    <Settings /> 
                                    // <div>
                                    //     <img
                                    //         src={`/images/footer_icon/setting.png`}
                                    //         // src={`/images/footer_icon/${selectedIcon === 'account' ? 'setting-sel.png' : 'setting.png'}`}
                                    //         alt={t('title.setting')}
                                    //         className="img-style"
                                    //         loading='lazy'
                                    //     />
                                    // </div>
                                }
                                to={accessToken ? "/account" : "/login"}
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />
                        </BottomNavigation>
                        {
                            // _.includes(SELLING_BTN, location.pathname)
                            //     ?
                            //     <div className='flex-c-b'>
                                            
                            //         <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }} id='box'>
                            //             <SpeedDial
                            //                 id='SpeedDial'
                            //                 ariaLabel="SpeedDial tooltip example"
                            //                 sx={{
                            //                     position: 'absolute', 
                            //                     bottom: 20, 
                            //                     left: '46vw',
                            //                     '@media (max-width: 800px)': {
                            //                         left: "45vw", 
                            //                     },
                            //                     '@media (max-width: 600px)': {
                            //                         left: "44vw", 
                            //                     },
                            //                     '@media (max-width: 550px)': {
                            //                         left: "43vw", 
                            //                     },
                            //                     '@media (max-width: 500px)': {
                            //                         left: "42vw", 
                            //                     },
                            //                     '@media (max-width: 350px)': {
                            //                         left: "40vw", 
                            //                     },
                            //                     '@media (max-width: 300px)': {
                            //                         left: "38vw", 
                            //                     },
                            //                     '@media (max-width: 250px)': {
                            //                         left: "36vw", 
                            //                     },
                            //                     '& .MuiFab-primary': {
                            //                         color: '#eee', 
                            //                         background: theme.palette.primary.main, 
                            //                         width: 65,
                            //                         height: 65, 
                            //                         borderRadius: 48, 
                            //                         boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 3px 3px 3px 0px rgba(255, 255, 255, 0.76) inset, -2px -2px 3px 0px rgba(0, 0, 0, 0.14) inset',
                            //                         '&:hover': { 
                            //                             backgroundColor: theme.palette.secondary.main 
                            //                         }
                            //                     },
                            //                 }}
                            //                 icon={<SpeedDialIcon />}
                            //                 onClose={() => setTradeOpen(false)}
                            //                 onOpen={handleTradeOpen}
                            //                 open={tradeOpen}
                            //             >
                            //                 {tradeActions.map((action) => (
                            //                     <SpeedDialAction
                            //                         key={action.name}
                            //                         icon={
                            //                             <Box>
                            //                                 {action.icon}
                            //                                 <br />
                            //                                 <Typography style={{ fontSize: 9 }}>{action.name}</Typography>
                            //                             </Box>
                            //                         }
                            //                         // tooltipTitle={action.name}
                            //                         // tooltipOpen
                            //                         onClick={() => handleLinkClick(action.urlLink)}
                            //                         sx={{
                            //                             width: 55,
                            //                             height: 55,
                            //                             lineHeight: "normal",
                            //                         }}
                            //                     />
                            //                 ))}
                            //             </SpeedDial>
                            //         </Box>
                            //     </div>
                            //     :
                            //     null
                        }
                    </Paper>
                    :
                    null
            }
        </>
    )
});

export default BottomNavigationBar;

const sellingBtnStyle = createTheme({
    overrides: {
        MuiBottomNavigationAction:{
            label:{
            //    paddingTop:30
            },
        }
    }
})