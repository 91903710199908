import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import { colors } from '@mui/material';

const MAIN_COLOR = '#eed189';
const SECONDARY_COLOR = '#F0F0F0';
const DARKBASE = '#141418';
const WHITETEXT = '#F0F0F0';

let theme = createTheme({
    palette: {
        primary: {
            main: MAIN_COLOR,
            secondary: SECONDARY_COLOR,
            linear: `linear-gradient(90deg, ${MAIN_COLOR} 0%, ${MAIN_COLOR} 78.5%, ${SECONDARY_COLOR} 100%)`,
        },
        secondary: {
            main: '#1E1E1E',
        },
        green: {
            main: green[500],
        },
        red: {
            main: '#e33a3a',
        },
        gray: {
            main: '#565656',
            secondary: '#424242',
            ltext: '#b7b7b7',
            dtext: '#606060',
            light: '#eaeaea',
            tableHeader: '#dbdbdb',
            tableHeaderText: '#606060',
        },
        darkBase: {
            main: DARKBASE,
        },
        lightGray: {
            main: '#A7A7A7',
        },
        darkGray: {
            main: '#787878',
        },
        white: {
            main: '#d7d6d6'
        },
        darkBlack: {
            main: '#000000',
        },
        indicator: {
            green: '#47ba80',
            red: '#f46464',
            yellow: '#f4c04e',
            disable: '#eaeaea',
        },
        button: {
            main: MAIN_COLOR,
            borderMain: `2px solid ${MAIN_COLOR}`,
            darkRed: '#cd4949',
            yellow: '#ffcb58',
            green: '#47ba80'
        },
        backgroundColor: '#EEEEEE',
    },
    components: {
        MuiBottomNavigation: {
            styleOverrides: {
                // Name of the slot
                root: {
                    height: 63,
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: `${WHITETEXT}`,
                    minWidth:'20%'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: DARKBASE,
                    minWidth: 100,
                    textTransform: 'capitalize',
                    borderTopLeftRadius: 30,
                    borderBottomRightRadius: 30,
                    // boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15), -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset',
                    boxShadow:'none',
                    border:'1px solid transparent',
                    background: 'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #bf863b, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
                    '&.$Mui-disabled': {
                        backgroundColor: 'transparent',
                        background:'transparent'
                    }
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                    // On hover state of underline.
                    '& .MuiInputBase-root.MuiInput-root:hover::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root::after': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Default state of text input field font.
                    '& .MuiInputBase-input.MuiInput-input': {
                        color: '#fffffff0',
                    },

                    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                        WebkitTextFillColor: '#2E3133',
                    },

                    '& .MuiInputBase-root': {
                        color: '#fffffff0',
                    },

                    '& .MuiFormLabel-root.Mui-focused': {
                        color: '#fffffff0',
                    },

                    '& .MuiFormLabel-root.Mui-disabled': {
                        color: '#2E3133',
                    },

                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },

                    // On hover state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl:hover::before': {
                        color: '#fffffff0',
                    },

                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.Mui-focused::after': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: '#ffffff0a',
                    borderRadius: 10,
                    flexShrink: 0,
                    height: 60,
                    color:`${WHITETEXT}`,
                    padding: 15,
                    textDecorationLine: "none",
                    borderBottom: '2px solid #b07518',
                    boxShadow:'-2px -2px 5px 0 #000000bf',
                    // -2px -2px 5px 0 #000000bf, 2px 2px 2px 0 #ffffff2e
                },
                '& MuiInputBase-root-MuiOutlinedInput-root .MuiSelect-select': {
                        color:'#fff'
                    },
                input: {
                    '&:-webkit-autofill': {
                        WebkitTextFillColor: '#fffffff0',
                        borderRadius: '0px !important',
                        transition: 'background-color 5000s ease-in-out 0s',
                    },
                    '&::placeholder': {
                        color: '#fffffff0',
                        opacity: 1
                    }
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#565656',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    ":after": {
                        borderTop: "thin solid #ffffff"
                    },
                    ":before": {
                        borderTop: "thin solid #ffffff"
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: `${MAIN_COLOR}`,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#d7d6d6',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                // root: {
                //     color: '#565656',
                //     borderRadius: '48px',
                //      
                // },
                root: {
                    // Some CSS
                    minWidth:150,
                    cursor:'pointer',
                    color: '#d7d6d6',
                    textTransform: 'uppercase',
                    background:'transparent',
                    fontSize: '16px',
                    minHeight: "40px",
                    height: "40px", 
                    fontWeight: 'bold',
                    borderRadius: '15px 15px 0 0',
                    '&.Mui-selected':{
                        background: 'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #bf863b, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5)',
                        color: '#000 !important',
                    }
                  },
                
            },
        },

        MuiTabs: {
            styleOverrides: {
                root: {
                    borderRadius: '48px',
                    // background: '#1E1E1E',
                    // boxShadow: '3px 3px 4px 0px #FFF, 3px 3px 2px 0px rgba(0, 0, 0, 0.10) inset'
                    minHeight: "40px",
                },
                indicator: {
                    backgroundColor: 'transparent',
                },
                scrollButtons: {
                    color: '#565656',
                },
                scroller:{
                    overflow:'unset!important',
                    // paddingTop:20
                  }
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#787878',
                },
                arrow: {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: `${MAIN_COLOR}`,
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root:{
                    color:'#ffffffb3',
                    "&.Mui-active":{
                        color: '#e8be79'
                    }
                },
                // text: {
                //     fill: '#d7b470'
                // }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
                underline: {
                    "&&&:before": {
                        borderBottom: "none"
                    },
                    "&&:after": {
                        borderBottom: "none"
                    }
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    ".MuiOutlinedInput-notchedOutline": {
                        border: 0
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                    },
                },
                select: {
                    padding: 0,
                    color: `${WHITETEXT}`
                },
                icon: {
                    color: `${WHITETEXT}`
                }
            }
        },
        MuiStepLabel:{
            styleOverrides: {
                labelContainer: {
                    color:'#ffffffd1'
                },
                label:{
                    "&.Mui-active": {
                        color:`${MAIN_COLOR}`
                    },
                    "&.Mui-completed": {
                        color:`${MAIN_COLOR}`
                    }
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#D6A364',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    color: '#82512D'
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper:{
                    border:'2px solid transparent',
                    color:'#ffffffb0',
                    fontWeight:500,
                    borderRadius:15,
                }
            }
        },
        // MuiPaper: {
        //     styleOverrides: {
        //         root: {
        //             border:'2px solid transparent',
        //             color:'#ffffffb0',
        //             fontWeight:500,
        //             borderRadius:15
                   
        //         }
        //     }
        // },
        MuiDialogContent:{
            styleOverrides:{
                root:{
                    backgroundColor:'transparent !important',
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingBottom: 24,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingRight: 0,
                    paddingLeft: 0,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#F0F0F0',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#7f7f7f',
                    "&.Mui-error":{
                        color:'#e33a3a'
                    }
                },
            },
        },
        // MuiCssBaseline: {
        //     styleOverrides: (themeParam) => `
        //         & .MuiBottomNavigation-root &.Mui-selected {
        //             border: 2px solid transparent;
        //             background:
        //               linear-gradient(#111115,#111115) padding-box,
        //               linear-gradient(280deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f);
        //             color: ${WHITETEXT} !important;
        //         },
        //         & .MuiTab-root.Mui-selected{
        //             border: 2px solid transparent;
        //             background:
        //               linear-gradient(#111115,#111115) padding-box,
        //               linear-gradient(280deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f);
        //             color: ${WHITETEXT} !important;
        //         },
        //         &.MuiPaginationItem-root.Mui-selected{
        //             border: 2px solid transparent;
        //             background:
        //               linear-gradient(#111115,#111115) padding-box,
        //               linear-gradient(280deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f);
        //             color: ${WHITETEXT}
        //         }
        //     `,
        // },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    maxWidth: '100%',
                    margin: '0 auto',
                    backgroundColor:'#F7F7F7'
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                backgroundColor:'#141418',
                boxShadow:'3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset'
              },
              bar1Determinate: {
                background: MAIN_COLOR,
                borderRadius:48,
                boxShadow: '2px 0px 2px 0px rgba(0, 0, 0, 0.10), -2px -2px 4px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.44) inset;'
              }
            },
        },
        MuiTreeItem:{
            styleOverrides: {
                label: {
                    backgroundColor:'transparent',
                    '&:hover':{
                        backgroundColor:'transparent',
                    }
                },
                root:{
                    '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
                        backgroundColor:'transparent',
                    },
                }
            }
        },
    }
});

theme = responsiveFontSizes(theme);

export default theme;